import 'photoswipe/dist/photoswipe.css'
import 'photoswipe/dist/default-skin/default-skin.css'
import PhotoSwipe from 'photoswipe'
import PhotoSwipeUI_Default from 'photoswipe/dist/photoswipe-ui-default'
import $ from 'jquery'

var imageProduto = null;
var hrefImgProduto = null;

const template = `<!-- Root element of PhotoSwipe. Must have class pswp. -->
  <div class="pswp" tabindex="-1" role="dialog" aria-hidden="true" data-turbo="false">

      <!-- Background of PhotoSwipe.
            It's a separate element, as animating opacity is faster than rgba(). -->
      <div class="pswp__bg"></div>

      <!-- Slides wrapper with overflow:hidden. -->
      <div class="pswp__scroll-wrap">

          <!-- Container that holds slides. PhotoSwipe keeps only 3 slides in DOM to save memory. -->
          <div class="pswp__container">
              <!-- don't modify these 3 pswp__item elements, data is added later on -->
              <div class="pswp__item" data-turbo="false"></div>
              <div class="pswp__item" data-turbo="false"></div>
              <div class="pswp__item" data-turbo="false"></div>
          </div>

          <!-- Default (PhotoSwipeUI_Default) interface on top of sliding area. Can be changed. -->
          <div class="pswp__ui pswp__ui--hidden">

              <div class="pswp__top-bar">

                  <!--  Controls are self-explanatory. Order can be changed. -->

                  <div class="pswp__counter"></div>

                  <button class="pswp__button pswp__button--close" data-turbo="false" title="Close (Esc)"></button>

                  <button class="pswp__button pswp__button--share" title="Share"></button>

                  <button class="pswp__button pswp__button--fs" title="Toggle fullscreen"></button>

                  <button class="pswp__button pswp__button--zoom" title="Zoom in/out"></button>

                  <!-- Preloader demo https://codepen.io/dimsemenov/pen/yyBWoR -->
                  <!-- element will get class pswp__preloader--active when preloader is running -->
                  <div class="pswp__preloader">
                      <div class="pswp__preloader__icn">
                          <div class="pswp__preloader__cut">
                              <div class="pswp__preloader__donut"></div>
                          </div>
                      </div>
                  </div>
              </div>

              <div class="pswp__share-modal pswp__share-modal--hidden pswp__single-tap">
                  <div class="pswp__share-tooltip"></div>
              </div>

              <button class="pswp__button pswp__button--arrow--left" title="Previous (arrow left)">
              </button>

              <button class="pswp__button pswp__button--arrow--right" title="Next (arrow right)">
              </button>

              <div class="pswp__caption">
                  <div class="pswp__caption__center"></div>
              </div>

          </div>
      </div>
  </div>`;

function getPhotoSwipeContainer() {
  const pswpElements = document.querySelectorAll('.pswp');
  if (pswpElements.length > 0) {
    return pswpElements[0];
  }

  const pswpElement = $(template);
  $(document.body).append(pswpElement);

  return pswpElement.find('> div')[0]
}

function openPhotoSwipe(event) {
  event.preventDefault();
  const index = $(this).data('img-index') || 0;

  const pswpElement = getPhotoSwipeContainer();
  const imagens = JSON.parse(this.dataset.images);

  // build items array
  var items = imagens.map(function (imagem) {
    return {
      src: imagem,
      w: 1100,
      h: 1100
    }
  });

  // define options (if needed)
  var options = {
    // history & focus options are disabled on CodePen
    history: true,
    focus: true,
    showAnimationDuration: 0,
    hideAnimationDuration: 0,
    index: parseInt(index),
    shareEl: false,
    bgOpacity: 0.9
  };

  const gallery = new PhotoSwipe(pswpElement, PhotoSwipeUI_Default, items, options);
  gallery.init();
};

function mudarImagemProduto(element) {
  imageProduto.src = element.getAttribute('data-url-imagem');
  hrefImgProduto.data('img-index', element.getAttribute('data-img-index'));
  hrefImgProduto.attr('href', element.getAttribute('data-url-full-imagem'));
}

export const init = () => {
  hrefImgProduto = $('#href-img-prod');

  if (hrefImgProduto.length === 0) {
    return;
  }

  getPhotoSwipeContainer();
  hrefImgProduto.click(openPhotoSwipe);
  imageProduto = hrefImgProduto.find('img')[0];

  $('[data-mudar-imagem-produto]').click(function () {
    mudarImagemProduto(this);
  });

  if ($(window).width() > 991) {
    $('.bxslider').bxSlider({
      mode: "vertical",
      slideMargin: 9,
      minSlides: 3,
      maxSlides: 3,
      pager: false,
      controls: true
    });
    return;
  }

  $('.bxslider').bxSlider({
    mode: "horizontal",
    slideWidth: 200,
    slideMargin: 5,
    minSlides: 1,
    maxSlides: 4,
    pager: false,
    controls: true
  });
}
