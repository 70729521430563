const SANDBOX_CARD_NUMBERS = [
    '0000000000000000',
    '0000000000000001',
    '0000000000000002',
    '0000000000000003',
    '0000000000000004',
    '0000000000000005',
    '0000000000000006',
    '0000000000000007',
    '0000000000000008',
    '0000000000000009',
];

// Luhn Algorithm
// Reference: https://rosettacode.org/wiki/Luhn_test_of_credit_card_numbers#JavaScript
export const checkCardNumber = (cardNumber) => {
    cardNumber = cardNumber.toString();
    if (cardNumber == '') return false;

    const sumDigits = (char) =>
        char < 10 ? char : sumDigits(Math.trunc(char / 10) + (char % 10));

    return (
        SANDBOX_CARD_NUMBERS.includes(cardNumber) ||
        cardNumber
            .toString()
            .split('')
            .reverse()
            .map(Number)
            .map((char, index) =>
                index % 2 !== 0 ? sumDigits(char * 2) : char,
            )
            .reduce((acc, curr) => acc + curr) %
            10 ===
            0
    );
};
