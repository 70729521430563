import '@webcomponents/custom-elements';
import $ from 'jquery';
window.jQuery = window.$ = $;

import './styles';

import 'bootstrap-datepicker';
import 'bootstrap-datepicker/js/locales/bootstrap-datepicker.pt-BR.js';

import 'select2/dist/js/select2';

import 'inputmask/dist/inputmask/inputmask.phone.extensions';
import 'inputmask/dist/jquery.inputmask.bundle';

import 'bxslider/dist/jquery.bxslider.js';

import './jquery-maskMoney';
import './jquery-masked-input-plugin';
import './jquery-alphanumeric-pack';

import PerfectScrollbar from 'perfect-scrollbar';

import { init as initPhotoSwipe } from './photoswipe';

import 'pusher-js';

import * as ajaxModal from './ajaxModal.js';
import * as messageHelpers from './messages.js';
import * as errorHelpers from './error.js';
import * as turboHelper from './turbo.js';
import * as mask from './masks.js';
import {
    setup as setupPusher,
    getPusher,
    waitEventToRefresh,
} from './pusher-ws';
import * as validation from './validation.js';

const { init: initAjaxModal, ...ajaxHelpers } = ajaxModal;
const { applyMask, ...maskHelpers } = mask;

const globalize = (obj) =>
    Object.keys(obj).map((prop) => (window[prop] = obj[prop]));

globalize({
    ...turboHelper,
    ...errorHelpers,
    ...ajaxHelpers,
    ...maskHelpers,
    ...messageHelpers,
    ...validation,
    setupPusher,
    getPusher,
    waitEventToRefresh,
});

$(document).on('turbo:load', () => {
    applyMask(document.body);
    initPhotoSwipe();
    if ($('#notification-area .notifications-body').length == 0) {
        return;
    }

    new PerfectScrollbar('#notification-area .notifications-body', {
        wheelPropagation: true,
    });
});

$(() => {
    $('body').on('click', function (e) {
        $('[data-toggle=popover]').each(function () {
            // hide any open popovers when the anywhere else in the body is clicked
            if (
                !$(this).is(e.target) &&
                $(this).has(e.target).length === 0 &&
                $('.popover').has(e.target).length === 0
            ) {
                $(this).popover('hide');
            }
        });
    });

    $(document).on('click', '.copy-to-clipboard', function (event) {
        $($(event.currentTarget).data('input-target')).select();
        document.execCommand('copy');
    });

    const render = () => {
        $('.select2-input').select2({
            placeholder: 'Selecione...',
            allowClear: true,
        });
    };

    $(document)
        .on('turbo:before-cache', () => $('.popover').popover('destroy'))
        .on('turbo:load', render);

    initAjaxModal();

    $(document).trigger('turbo:load');
});
