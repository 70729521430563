import jQuery from 'jquery';

/*
 * jquery alphanumeric pack
 * Version: 1.0
 * Release: 2009-04-04
 * Incluido por Renato Medina
 */
(function($) {
    $.fn.alphanumeric = function(p) {
        p = $.extend(
            {
                ichars: '!@#$%^&*()+=[]\\\';,/{}|":<>?~`.-``´´¨',
                nchars: '',
                allow: '',
            },
            p,
        );

        return this.each(function() {
            if (p.nocaps) p.nchars += 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
            if (p.allcaps) p.nchars += 'abcdefghijklmnopqrstuvwxyz';

            var s = p.allow.split('');
            for (var i = 0; i < s.length; i++)
                if (p.ichars.indexOf(s[i]) != -1) s[i] = '\\' + s[i];
            p.allow = s.join('|');

            var reg = new RegExp(p.allow, 'gi');
            var ch = p.ichars + p.nchars;
            ch = ch.replace(reg, '');

            $(this).keypress(function(e) {
                const k = !e.charCode
                    ? String.fromCharCode(e.which)
                    : String.fromCharCode(e.charCode);

                if (ch.indexOf(k) != -1) e.preventDefault();
                if (e.ctrlKey && k == 'v') e.preventDefault();
            });
        });
    };

    $.fn.numeric = function(p) {
        var az = 'abcdefghijklmnopqrstuvwxyz';
        az += az.toUpperCase();

        p = $.extend({ nchars: az }, p);

        return this.each(function() {
            $(this).alphanumeric(p);
        });
    };

    $.fn.alpha = function(p) {
        var nm = '1234567890';

        p = $.extend({ nchars: nm }, p);

        return this.each(function() {
            $(this).alphanumeric(p);
        });
    };
})(jQuery);
